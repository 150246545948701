import {
  HttpClient,
  HttpHeaders,
  HttpResponseBase,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  BuscaEmail,
  CheckToken,
} from 'src/app/core/models/service/cadastroCliente';
import {
  cadastraSenha,
  carregaDadosCliente,
  carregaEmail,
  checkToken,
  erroCadastraSenha,
  erroCarregarDadosCliente,
  erroCarregarEmail,
  erroCheckToken,
  erroReenviarToken,
  reenviaToken,
  sucessoCadastraSenha,
  sucessoCarregaEmail,
  sucessoCarregarDadosCliente,
  sucessoReenviarToken,
  sucessoCheckToken,
  confirmaDadosCliente,
  sucessoConfirmaDadosCliente,
  erroConfirmaDadosCliente,
  cadastroClienteNeta,
  sucessoCadastroClienteNeta,
  erroCadastroClienteNeta,
} from './cadastro-cliente.actions';

import { ICadastroState } from './cadastro-cliente.reducer';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { Regex } from 'src/app/shared/utils/regex';

@Injectable({
  providedIn: 'root',
})
export class CadastroClienteEffectService {
  private readonly BASE_URL = `${environment.uri}`;

  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<{ cadastro: ICadastroState }>,
    private tokensStorage: TokenStorageService
  ) {}

  carregarEmail = createEffect(() =>
    this.actions$.pipe(
      ofType(carregaEmail),
      mergeMap((action) => {
        action.cpf = Regex.removeMaskCpf(action.cpf);
        const headers = new HttpHeaders({
          'X-ReCaptcha-Token': action?.recaptcha ?? '',
        });
        return this.http
          .get<BuscaEmail>(
            `${this.BASE_URL}v1/primeiro/acesso/cliente/codigo/cpf/${action.cpf}`,
            { headers, observe: 'response' }
          )
          .pipe(
            map((response) =>
              sucessoCarregaEmail({
                dados: response.body,
                status: response.status,
              })
            ),
            catchError((error) => {
              return of(erroCarregarEmail({ error: error }));
            })
          );
      })
    )
  );

  carregarToken = createEffect(() =>
    this.actions$.pipe(
      ofType(checkToken),
      mergeMap((action) =>
        this.http
          .post<CheckToken>(
            `${this.BASE_URL}v1/primeiro/acesso/cliente/codigo/check`,
            action.req
          )
          .pipe(
            map((response) => sucessoCheckToken({ dados: response })),
            catchError((error) => {
              return of(erroCheckToken({ error: error }));
            })
          )
      )
    )
  );

  reenviaToken = createEffect(() =>
    this.actions$.pipe(
      ofType(reenviaToken),
      mergeMap((action) => {
        const headers = new HttpHeaders({
          'X-ReCaptcha-Token': action.recaptcha,
        });
        return this.http
          .get<any>(
            `${this.BASE_URL}v1/primeiro/acesso/cliente/codigo/reenvia/cpf/${action.cpf}`,
            { headers }
          )
          .pipe(
            map((response) => sucessoReenviarToken({ dados: response })),
            catchError((error) => {
              return of(erroReenviarToken({ error: error }));
            })
          );
      })
    )
  );

  carregarDadosCliente = createEffect(() =>
    this.actions$.pipe(
      ofType(carregaDadosCliente),
      mergeMap((action) => {
        const authToken = this.tokensStorage.getTokenPrimeiroAcesso();
        if (authToken) {
          const headers = new HttpHeaders({
            Authorization: `Bearer ${authToken}`,
          });
          return this.http
            .get<any>(
              `${this.BASE_URL}v1/primeiro/acesso/cliente/dados/${action.cpf}`,
              { headers }
            )
            .pipe(
              map((response) =>
                sucessoCarregarDadosCliente({ dados: response })
              ),
              catchError((error) => {
                return of(erroCarregarDadosCliente({ error: error }));
              })
            );
        } else {
          return of(
            erroCarregarDadosCliente({ error: 'Token de autorização ausente' })
          );
        }
      })
    )
  );

  confirmaDadosCliente = createEffect(() =>
    this.actions$.pipe(
      ofType(confirmaDadosCliente),
      mergeMap((action) => {
        const authToken = this.tokensStorage.getTokenPrimeiroAcesso();
        if (authToken) {
          const headers = new HttpHeaders({
            Authorization: `Bearer ${authToken}`,
          });
          return this.http
            .post<any>(
              `${this.BASE_URL}v1/primeiro/acesso/cliente/dados/salvar`,
              action.clienteDados,
              { headers }
            )
            .pipe(
              map((response) =>
                sucessoConfirmaDadosCliente({ protocolo: response })
              ),
              catchError((error) => {
                return of(erroConfirmaDadosCliente({ error: error }));
              })
            );
        } else {
          return of(
            erroConfirmaDadosCliente({ error: 'Token de autorização ausente' })
          );
        }
      })
    )
  );

  cadastroNovoClienteNeta = createEffect(() =>
    this.actions$.pipe(
      ofType(cadastroClienteNeta),
      mergeMap((action) => {
        const authToken = this.tokensStorage.getTokenPrimeiroAcesso();
        if (authToken) {
          const headers = new HttpHeaders({
            Authorization: `Bearer ${authToken}`,
          });
          return this.http
            .post<any>(
              `${this.BASE_URL}v1/primeiro/acesso/cliente/novo/confirmar`,
              action.clienteDadosCompleto,
              { headers }
            )
            .pipe(
              map((response) =>
                sucessoCadastroClienteNeta({ protocolo: response })
              ),
              catchError((error) => {
                return of(erroCadastroClienteNeta({ error: error }));
              })
            );
        } else {
          return of(
            erroConfirmaDadosCliente({ error: 'Token de autorização ausente' })
          );
        }
      })
    )
  );

  cadastrarSenha = createEffect(() =>
    this.actions$.pipe(
      ofType(cadastraSenha),
      mergeMap((action) => {
        const authToken = this.tokensStorage.getTokenPrimeiroAcesso();
        if (authToken) {
          const headers = new HttpHeaders({
            Authorization: `Bearer ${authToken}`,
          });
          return this.http
            .post<any>(
              `${this.BASE_URL}v1/primeiro/acesso/cliente/dados/senha`,
              action.req,
              { headers }
            )
            .pipe(
              map(() => sucessoCadastraSenha()),
              catchError((error) => {
                return of(erroCadastraSenha({ error: error }));
              })
            );
        } else {
          return of(
            erroCadastraSenha({ error: 'Token de autorização ausente' })
          );
        }
      })
    )
  );
}
