import { TokenClienteCredito } from '../core/models/pagamentoCartao';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartaoPaliativoServices {
  private readonly BASE_URL = `${environment.uri}`;
  constructor(private http: HttpClient) { }

  geraUrlPag(card: any): Observable<any> {
    return this.http.post<TokenClienteCredito>(
      `${this.BASE_URL}v1/completa/fatura/pagamento/cartao/criarlink`, card
      // `${this.BASE_URL}v1/fastdata/cartao/crialink`, card
    );
  }
}