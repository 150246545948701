export class Responsavel {
  tipo: string;
  nome: string;
  sobrenome: string;
  celular: string;
  solicitante: string;
  email?: string;
  
  constructor() {
    this.tipo = '';
    this.nome = '';
    this.sobrenome = ''
    this.celular = '';
    this.solicitante = '';
    this.email = '';
  }
}
