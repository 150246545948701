import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Auth } from 'src/app/core/models/auth';
import { Fornecimento } from 'src/app/core/models/fornecimento';

@Injectable({
  providedIn: 'root'
})
export class FornecimentoService implements OnDestroy {
  private fornecimentosSource = new BehaviorSubject<Fornecimento[]>(this.loadFornecimentosFromStorage());
  public currentFornecimentos = this.fornecimentosSource.asObservable();

  private fornecimentoSource = new BehaviorSubject<Fornecimento>(new Fornecimento());
  public currentFornecimento = this.fornecimentoSource.asObservable();

  private destroy$ = new Subject<void>();


  constructor() {
    this.currentFornecimentos.pipe(takeUntil(this.destroy$)).subscribe(fornecimentos => {
      this.saveFornecimentosToStorage(fornecimentos);
    });
  }

  changeFornecimentos(fornecimentos: Fornecimento[]) {
    this.fornecimentosSource.next(fornecimentos);
  }

  changeFornecimento(fornecimento: Fornecimento) {
    this.fornecimentoSource.next(fornecimento);
  }

  private saveFornecimentosToStorage(fornecimentos: Fornecimento[]) {
    let jwt = localStorage.getItem("auth-token")
    sessionStorage.setItem(`fornecimentos-${jwt}`, JSON.stringify(fornecimentos));
  }

  private loadFornecimentosFromStorage(): Fornecimento[] {
    let jwt = localStorage.getItem("auth-token")
    const fornecimentos = localStorage.getItem(`fornecimentos-${jwt}`);
    return fornecimentos ? JSON.parse(fornecimentos) : [];
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
