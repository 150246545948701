import {
  Attribute,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  Endereco,
  EnderecoBuscaPla,
  Tipocivico,
  Toponimo,
} from 'src/app/core/models/enderecos/endereco';
import { InputSelect } from 'src/app/shared/components/agv-material/agv-inputs/input-select/input-select.model';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FornecimentoService } from 'src/app/shared/observables/fornecimento.service';
import { ClienteService } from 'src/app/shared/observables/cliente.service';
import { Fornecimento } from 'src/app/core/models/fornecimento';
import { ClienteCompleto } from 'src/app/core/models/service/cadastroCliente';
import {
  Observable,
  Subject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { MunicipioAgencia } from 'src/app/core/models/canais-atendimento';
import { TokenStorageService } from 'src/app/services/tokenStorageServices.service';
import { Regex } from 'src/app/shared/utils/regex';
import { EnderecoService } from 'src/app/services/enderecos.service';
import { EnderecosObservableService } from '../../observables/endereco.service';
import { environment } from 'src/environments/environment';

export interface IRequestDadosEnderecos {
  municipios: MunicipioAgencia[];
  toponimos: Toponimo[];
  tipoCivicos: Tipocivico[];
}

@Component({
  selector: 'agv-localizacao',
  templateUrl: './localizacao.component.html',
  styleUrls: ['./localizacao.component.scss'],
})
export class LocalizacaoComponent implements OnInit, OnDestroy {
  @Output() podeContinua: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() nomeTela: string = ''
  @Output() cancelar: EventEmitter<any> = new EventEmitter<any>();

  selectOptionLocalizar: InputSelect = new InputSelect();
  selectOptionFornecimento: InputSelect = new InputSelect();

  opcoesSelectLocalizar: { value: string; label: string }[] = [
    { value: 'fornecimento', label: 'Fornecimento' },
    { value: 'endereco', label: 'Endereço' },
  ];
  opcoesSelectFornecimento: { value: string; label: string }[] = [
    { value: '', label: 'Outro fornecimento' },
  ];

  optionEscolhida: string | null = null;
  fornecimentoSelecionado: string | null = null;

  endereco: Endereco = new Endereco();
  tipocivicoCorrespondencia: Tipocivico[];
  toponimosCorrespondencia: Toponimo[];
  lstFornecimento: Fornecimento[] = [];
  cliente: ClienteCompleto;
  lstaEndereco: EnderecoBuscaPla[] = [];
  currentTheme: string;

  dadosEnderecos: IRequestDadosEnderecos | null = null;

  botaoContinuarHabilitado: boolean = false;
  loadingEnd: boolean = false;
  abreListaEndereco: boolean = false;
  public loadingOutroFornecimento: boolean = false;
  loadingEndereco: boolean = false;
  public loading: boolean = false;
  temManobra: boolean = false;

  opcao: any = null;
  erros: any | null = null;
  fornecimento: string | null;
  public errorMessage: any | null = null;
  tokenRecaptcha: string = '';
  recaptchaSelecionado: boolean = false;
  mostrarRecaptcha: boolean = false;
  mensagemErroInput: string | null = null;
  lodingRecaptha: boolean = false;
  @ViewChild('divRecaptcha')
  divRecaptcha!: ElementRef<HTMLDivElement>;




  enderecoForm: FormGroup = this.fb.group({
    bairro: [{ value: '', disabled: true }],
    endereco: ['', Validators.required],
    toponimo: ['', Validators.required],
    municipio: ['', Validators.required],
    codMunicipio: ['', Validators.required],
    idLogradouro: ['', Validators.required],
    tipoCivico: ['', Validators.required],
    numero: ['', Validators.required],
    complemento: [''],
  });

  public destroy$: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private fornObs: FornecimentoService,
    private cliObs: ClienteService,
    private enderecoService: EnderecoService,
    public fb: FormBuilder,
    public enderecosObservableServices: EnderecosObservableService,
    private storageService: TokenStorageService,
    private ngZone: NgZone,

    @Attribute('servico') private servico: string
  ) { 
    if (environment.disabledRecaptcha) {
      this.recaptchaSelecionado = true;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  atualizarFormulario(event: any) {
    this.enderecoForm.patchValue(event);
  }

  ngOnInit(): void {

    this.enderecoForm
      .get('endereco')
      ?.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.onInputChange();
      });

    let fornecimento = this.storageService.getFornecimentos();

    if (fornecimento && fornecimento?.length > 0) {
      this.lstFornecimento = fornecimento;
    } else {
      this.fornObs.currentFornecimentos
        .pipe(takeUntil(this.destroy$))
        .subscribe((fornecimentos) => {
          if (fornecimentos.length > 0) {

            this.lstFornecimento = fornecimentos;
            this.storageService.setFornecimentos(this.lstFornecimento ?? []);
          }
        });
    }

    // Carrega dados do cliente logado
    this.cliObs.currentCliente
      .pipe(takeUntil(this.destroy$))
      .subscribe((cliente) => {
        this.cliente = cliente ?? new ClienteCompleto();
      });
    this.carregaInfoSelectLocalizar();
    this.carregaInfoSelectFornecimento();
    this.carregaItensEndereco();
  }

  onInputChange(): void {
    const { endereco } = this.enderecoForm.getRawValue();
    if (endereco?.length >= 1) {
      this.listaEndereco();
    }
  }

  private carregaItensEndereco() {
    forkJoin({
      municipios: this.enderecoService.listaMunicipios(),
      toponimos: this.enderecoService.listaToponimos(),
      tipoCivicos: this.enderecoService.listaTipoCivicoImovel(),
    })
      .pipe(takeUntil(this.destroy$), distinctUntilChanged(), take(1))
      .subscribe({
        next: (result: IRequestDadosEnderecos) => {
          if (result) {
            this.dadosEnderecos = result;
          }
        },
        error: (error) => {
          this.errorMessage = error.error?.message || error.message;
          return throwError(() => error);
        },
      });
  }

  listaEndereco() {
    let { municipio, toponimo, endereco } = this.enderecoForm.getRawValue();
    if (municipio && toponimo && endereco) {
      this.loadingEndereco = true;
      let objeto = {
        municipio,
        toponimo,
        endereco,
      };
      this.enderecoService
        .buscarEndereco(objeto)
        .pipe(takeUntil(this.destroy$), take(1))
        .subscribe({
          next: (data: any) => {
            this.loadingEndereco = false;
            this.lstaEndereco = data ?? [];
            this.abreListaEndereco = this.lstaEndereco.length > 0;
          },
          error: (error) => {
            this.loadingEndereco = false;
          },
        });
    }
  }

  carregaInfoSelectLocalizar() {
    this.selectOptionLocalizar.label = 'Localizar por:';
    this.selectOptionLocalizar.placeholder =
      'Selecione a forma de identificação do local';
    this.selectOptionLocalizar.options = this.opcoesSelectLocalizar.map(
      (opcao) => ({
        value: opcao.value,
        labels: [{ value: opcao.label, img: false }],
      })
    );
  }

  capturaInfoSelectLocalizar(event: any) {
    const value = event as string;
    this.botaoContinuarHabilitado = false;
    this.fornecimento = null;
    this.errorMessage = null;
    this.optionEscolhida = value;
  }

  carregaInfoSelectFornecimento() {
    this.selectOptionFornecimento.label = 'Endereço';
    this.selectOptionFornecimento.placeholder = 'Selecione um fornecimento';
    const fornecimentosUsuario = this.lstFornecimento.map((fornecimento) => ({
      value: fornecimento.codigo,
      label: `${fornecimento.enderecoFornecimento}`,
    }));
    this.selectOptionFornecimento.options = [
      ...fornecimentosUsuario,
      ...this.opcoesSelectFornecimento,
    ].map((opcao) => ({
      value: opcao.value,
      labels: [{ value: opcao.label, img: false }],
    }));
  }

  validaFornecimento() {
    this.loadingOutroFornecimento = true;

    if (this.fornecimento) {
      this.validaFornecimentoObservable(this.fornecimento)
        .pipe(take(1), takeUntil(this.destroy$)).subscribe({
          next: () => {
            this.temManobra = false;
            this.botaoContinuarHabilitado = true;
            this.enderecosObservableServices.changeEnderecos({
              fornecimento: this.fornecimento,
              endereco: '',
              outroFornecimento: true,
            });
            this.loadingOutroFornecimento = false;
            this.continuar();
          },
          error: (error) => {
            this.loadingOutroFornecimento = false;
            this.validaErros(error);
          },
        });
    }
  }

  capturaInfoSelectFornecimento(fornecimento: any) {
    this.fornecimento = null;
    if (fornecimento) {
      this.fornecimentoSelecionado = fornecimento;
      const value = fornecimento.codigo as string;
      this.loading = true;
      if (value && value !== '') {
        this.validaFornecimentoObservable(value).subscribe({
          next: () => {
            this.temManobra = false;
            this.loading = false;
            this.botaoContinuarHabilitado = true;
            this.enderecosObservableServices.changeEnderecos({
              fornecimento: value,
              endereco: fornecimento.enderecoFornecimento,
            });
          },
          error: (error) => {
            this.loading = false;
            this.validaErros(error);
          },
        });
      }
    } else {
      this.fornecimentoSelecionado = fornecimento ?? '';
    }
  }

  validaFornecimentoObservable(fornecimento: string | null = null, idEndereco: string | null = null): Observable<any> {
    return this.enderecoService
      .validarFornecimento(this.servico, fornecimento, idEndereco)
      .pipe(
        take(1),
        takeUntil(this.destroy$),
        catchError((error) => {
          this.errorMessage = error.error?.message || error.message;
          return throwError(() => error);
        })
      );
  }

  verificarHabilitacaoBotaoContinuar(): boolean {
   
      if (
        this.fornecimentoSelecionado &&
        this.fornecimentoSelecionado !== '' &&
        !this.temManobra &&
        this.botaoContinuarHabilitado
      ) {
        return true;
      } else if (
        this.fornecimentoSelecionado === '' &&
        this.fornecimento &&
        !this.temManobra
      ) {
        this.botaoContinuarHabilitado = true;
        return true;
      } else if (
        this.optionEscolhida === 'Endereço' &&
        this.enderecoForm.valid &&
        !this.temManobra
      ) {
        this.botaoContinuarHabilitado = true;
        return true;
      } else {
        this.botaoContinuarHabilitado = false;
        return false;
      }
  }

  redirecionaContinuar() {
    if (!this.mostrarRecaptcha) {
      this.mostrarRecaptcha = true;
    } else {
      if (
        this.optionEscolhida === 'Fornecimento' &&
        this.fornecimentoSelecionado !== ''
      ) {
        this.continuar();
      } else if (this.optionEscolhida === 'Endereço') {
        if (this.enderecoForm.valid) {
          let { idLogradouro, tipoCivico, numero } =
            this.enderecoForm.getRawValue();
          if (idLogradouro && tipoCivico && numero) {
            let tipoCivicoId = this.dadosEnderecos?.tipoCivicos.find((t)=> t.tipoCivicoCodigo == tipoCivico)?.tipoCivicoId;
            let enderecoFornecimento = {
              logradouroId: idLogradouro,
              tipoCivicoId: tipoCivicoId,
              civico: numero,
            };
            this.loading = true;
            this.enderecoService
              .buscaFornecimento(enderecoFornecimento)
              .pipe(
                takeUntil(this.destroy$),
                switchMap((data: any) => {
                  if (data && data.idEndereco) {
                    this.enderecosObservableServices.changeEnderecos({
                      fornecimento: null,
                      idEndereco: data.idEndereco,
                      endereco: this.montaEndereco(this.endereco),
                    });
                    return this.validaFornecimentoObservable(null, data.idEndereco)
                  } else {
                    // Se não houver fornecimento no resultado, retorne um Observable vazio ou faça algum tratamento de erro.
                    return of(null);
                  }
                }),
                takeUntil(this.destroy$)
              )
              .subscribe({
                next: (validacaoData) => {
                  // Aqui você trata a resposta da validação
                  this.loading = false;

                  this.continuar();
                },
                error: (error) => {
                  // Trate os erros de ambas as chamadas aqui
                  this.loading = false;
                  this.errorMessage = {
                    titulo: error?.error?.description,
                    descricao: error?.error?.provider?.errorMessage,
                  };
                },
              });
          }
        }
      } else if (
        this.optionEscolhida === 'Fornecimento' &&
        this.fornecimentoSelecionado == '' &&
        this.fornecimento
      ) {
        this.validaFornecimento();
      } else {
        this.validaErros(Error);
      }
    }
  }

  recebeEnderecoFormulario(endereco: Endereco) {
    this.endereco = endereco;
  }

  continuar() {
    this.podeContinua.emit(true);
  }

  retornar() {
    this.router.navigate(['/fatura-completa']);
  }

  cancel(e: Event) {
    this.cancelar.emit();
  }

  validaErros(error: any) {
    this.errorMessage = {
      titulo: error?.error?.description ?? 'Serviço não disponível',
      descricao: error?.error?.provider?.errorMessage ?? 'Serviço não disponível',
    };
  }

  montaEndereco(endereco: Endereco) {
    return (
      Regex.capitalize(
        `${endereco.toponimoDescr} ${endereco.logradouroDescr}, ${endereco.tipoCivicoId} - ${endereco.bairroDescr} - ${endereco.municipioDescr}`
      ) + ' - SP'
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.renderizarReCaptcha();
    }, 800);
  }

  get grecaptcha(): any {
    const w = window as any;
    return w['grecaptcha'];
  }

  renderizarReCaptcha() {
    this.ngZone.runOutsideAngular(() => {
      if (!this.grecaptcha || !this.divRecaptcha) {
        setTimeout(() => {
          this.renderizarReCaptcha();
        }, 500);

        return;
      }

      const idElemento = this.divRecaptcha?.nativeElement?.getAttribute('id');

      if (this.grecaptcha && this.grecaptcha.enterprise) {
        this.grecaptcha?.enterprise?.render(idElemento, {
          sitekey: environment.siteKey,
          callback: (response: string) => {
            this.ngZone.run(() => {
              this.recaptchaSelecionado = true;
              this.tokenRecaptcha = response;
            });
          },
        });
      }
    });
  }
 
  resetRecaptcha() {
    this.removerReCaptcha();
    setTimeout(() => {
      this.renderizarReCaptcha();
    }, 800);
  }
  
  removerReCaptcha() {
    const elementoReCaptcha = document.getElementById('recaptcha');
    if (elementoReCaptcha && this.grecaptcha) {
      this.grecaptcha?.enterprise?.reset();
      this.recaptchaSelecionado = false;
    }
  }

  //verificar rotas que vai ter o recaptcha
  verificaRotaRecaptcha() {
    if (this.router.url === '/vazamentos/localizacao-vazamentos') {
      this.mostrarRecaptcha = true
    } else {
      this.mostrarRecaptcha = false
    }

  }
}
