<table class="tabela-agv">
  <thead>
    <tr [ngStyle]="{ height: alturaTabela.trHead }" class="tabela-agv__titulos">
      <th tabindex="0" class="checkbox-group" *ngIf="hasCheckbox">
        <input class="checkbox-header" type="checkbox" (change)="selectAll($event)" />
      </th>
      <th tabindex="0" [ngClass]="{ esquerda: alinhamentoColuna === 'left' }" *ngFor="let coluna of colunas">
        {{ coluna.display }}
      </th>
    </tr>
  </thead>
  <tbody [ngStyle]="{ height: alturaTabela.trBody }">
    <tr style="height: 41px" *ngFor="let item of dados" [ngClass]="{ inativo: item.ativo === 'Inativo' }">
      <td tabindex="0" class="checkbox-group" *ngIf="hasCheckbox">
        <input class="checkbox-body" type="checkbox" [checked]="isSelected(item)" (change)="toggleSelection(item)" />
      </td>
      <td [class]="columnHandlerStyle(column, item)" *ngFor="let column of colunas; let i = index" [ngClass]="{
          'acao-cell': column.field === 'editar' || column.field === 'remover',
          esquerda:
            (alinhamento === 'left' && i === 1) || alinhamentoColuna === 'left',
          'id-cell': column.field === 'id'
        }">
        <ng-container *ngIf="
            column.field !== 'editar' &&
              column.field !== 'remover' &&
              column.field !== 'ativo' &&
              column.field !== 'entrar';
            else actionButtons
          ">
          <!-- Verifica se o valor é uma data e aplica a formatação -->
          <ng-container *ngIf="isDate(item[column.field]); else displayColumn">
            <!-- Verifica se é uma data -->
            {{ newDate(item[column.field]) | date : "dd/MM/yyyy" }}
          </ng-container>
          <!-- Caso contrário, exibe o valor normal da coluna -->
          <ng-template #displayColumn>
            <!-- Verifica se é 'currency' -->
            <ng-container *ngIf="column.type === 'currency'; else normalColumn">
              {{ item[column.field] | currency : "BRL" }}
            </ng-container>

            <ng-template #favoriteCollum>
              <button mat-icon-button>
                <mat-icon>star</mat-icon>
              </button>
            </ng-template>

            <!-- Caso contrário, exibe o valor normal da coluna -->
            <ng-template #normalColumn>
              <ng-container *ngIf="column.icon; else favoriteCollum">
                <div tabindex="0" class="collum-details">
                  <div class="block-mat-icon">
                    <mat-icon [class]="getStatusClass(item?.status)">
                      fiber_manual_record_outline
                    </mat-icon>
                  </div>
                  <div class="content-span">
                    <span> {{ item[column.field] }}</span>
                  </div>
                </div>
              </ng-container>

              <ng-template #favoriteCollum>
                <ng-container *ngIf="column.type === 'favorite'; else ApelidoCollum">
                  <a tabindex="0" aria-label="Clique para favoritar CNPJ" (click)="selectFavorite(item)" class="favorite" [ngClass]="{ selecionado: item.favorito }">
                    <mat-icon>{{
                      item.favorito ? "star" : "star_outline"
                      }}</mat-icon>
                    <span [ngClass]="{ separator: !item[column.field] }"
                      [innerHTML]="item[column.field] ? item[column.field] : ''"></span>
                  </a>
                </ng-container>
              </ng-template>

              <ng-template #ApelidoCollum>
                <ng-container *ngIf="column.type === 'apelido'; else semIcone">
                  <div tabindex="0" class="apelido">
                    <span class="texto" *ngIf="!item.editando" [ngClass]="{ separator: !item[column.field] }"
                      [innerHTML]="item[column.field] ? item[column.field] : ''">
                    </span>
                    <input tabindex="0" *ngIf="item.editando" type="text" [(ngModel)]="item[column.field]" />
                    <button *ngIf="!item.editando" (click)="handleApelido(item)" mat-icon-button>
                      <mat-icon>edit</mat-icon>
                      <span class="button-texto">Editar</span>
                    </button>
                    <button class="salvar" *ngIf="item.editando" (click)="salvarApelido(item)" mat-icon-button>
                      <mat-icon>done</mat-icon>
                      <span class="button-texto">Salvar</span>
                    </button>
                  </div>
                </ng-container>
              </ng-template>

              <ng-template #semIcone>
                <p tabindex="0" [ngClass]="{ separator: !item[column.field] }"
                  [innerHTML]="item[column.field] ? item[column.field] : ''"></p>
              </ng-template>
            </ng-template>
          </ng-template>
        </ng-container>

        <ng-template #actionButtons>
          <button tabindex="0" *ngIf="column.field === 'editar'" (click)="editItem(item)">
            <div class="botao-wrapper">
              <agv-icon [ngClass]="{ 'dark-mode': currentTheme === 'theme-dark' }" [name]="'button-edit'"
                title="botão editar"></agv-icon>
              <span> Editar </span>
            </div>
          </button>
          <button tabindex="0" *ngIf="column.field === 'remover'" (click)="removeItem(item)">
            <div class="botao-wrapper">
              <agv-icon [ngClass]="{ 'dark-mode': currentTheme === 'theme-dark' }" title="icone remover item"
                [name]="'button-delete'"></agv-icon>
              <span>Remover</span>
            </div>
          </button>
          <div tabindex="0" *ngIf="column.field === 'ativo'" class="selector">
            <span [ngClass]="getSituacaoClass(item[column.field])">
              {{ item[column.field] }}
            </span>
            <mat-slide-toggle [checked]="item[column.field] === 'Ativo' ? true : false"
              (change)="alterarStatus($event, item)">
            </mat-slide-toggle>
          </div>
          <div class="botao-entrar-wrapper" *ngIf="column.field === 'entrar'">
            <agv-button [text]="'Entrar'" [type]="'1'" [habilita]="true" (sendAction)="apertaBotao(item)"></agv-button>
          </div>
        </ng-template>
      </td>
    </tr>
  </tbody>
</table>