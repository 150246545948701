import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ThemeService } from '../../observables/theme.service';
import { TransformText } from '../../utils/transformText';
interface StatusMapping {
  [key: string]: string;
}

interface linhaAltura {
  trHead: string;
  trBody: string;
}

@Component({
  selector: 'AGV-tabela',
  templateUrl: './tabela.component.html',
  styleUrls: ['./tabela.component.scss'],
})
export class TabelaComponent implements OnInit {
  // Controlador do tema
  currentTheme: string;
  @Input() hasCheckbox: boolean = false;
  @Input() colunas: any;
  @Input() dados: any[] = [];
  @Input() alinhamento: string;
  @Input() alinhamentoColuna: string;
  @Input() alturaTabela: linhaAltura = {
    trHead: '',
    trBody: '',
  };

  @Output() edit = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();
  @Output() ativar = new EventEmitter<any>();
  @Output() enviarSelecionados = new EventEmitter<any[]>();
  @Output() selecionaFavorito = new EventEmitter<any>();
  @Output() selecionaBotao = new EventEmitter<any>();
  @Output() mudarApelido = new EventEmitter<any>();

  ativo: boolean = false;
  favorito: boolean = false;
  selectedItems: any[] = [];

  apelido: string = '';
  editaApelido: boolean = false;

  statusClasses: StatusMapping = {
    PENDENTE: 'pendente',
    'EM ABERTO': 'aberto',
    FINALIZADO: 'finalizado',
    INTERROMPIDO: 'interrompido',
    CANCELADO: 'cancelado',
    // Adicione mais status conforme necessário
  };

  statusFornecimento: StatusMapping = {
    ATIVO: 'ativo',
    CORTADO: 'cortado',
    'ENCERRADO A FATURAR': 'encerrado-faturar',
    'ENCERRADO E FATURADO': 'encerrado-faturado',
  };

  faturaSituacoes: StatusMapping = {
    PAGA: 'paga',
    'PAGO COM CARTÃO CRÉDITO': 'paga',
    'EM ABERTO': 'em-aberto',
    'EM ATRASO': 'em-atraso',
    // Adicione mais situações conforme necessário
  };

  constructor(private theme: ThemeService) {}

  ngOnInit(): void {
    this.theme.currentTheme.subscribe((theme) => (this.currentTheme = theme));
  }

  editItem(item: any) {
    this.edit.emit(item);
  }

  removeItem(item: any) {
    this.remove.emit(item);
  }

  alterarStatus(event: MatSlideToggleChange, item: any) {
    const novoStatus = event.checked ? 'Ativo' : 'Inativo';
    this.ativar.emit({ item, novoStatus });
  }

  isDate(value: any): boolean {
    const timezonePattern =
      /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{7})((?:[\+\-]\d{2}:\d{2})|Z)$/;
    return timezonePattern.test(value);
  }

  getStatusClass(status: string): string {
    const statusNormalizado = status.toUpperCase().trim();
    switch (statusNormalizado) {
        case 'PENDENTE':
            return 'pendente';
        case 'EM ABERTO':
            return 'em-aberto';
        case 'FINALIZADO':
            return 'finalizado';
        case 'INTERROMPIDO':
            return 'interrompido';
        case 'CANCELADO':
            return 'cancelado';
        default:
            return '';
    }
}

  getStatusFornecimento(status: string): string {
    return this.statusFornecimento[status?.toUpperCase().trim()] || '';
  }

  getSituacaoFatura(status: string): string {
    return this.faturaSituacoes[status] || 'em-aguardo';
  }

  columnHandlerStyle(campo: any, item: any): string {
    if (campo.field === 'status' && campo.type === 'status-fatura') {
      return this.getStatusFornecimento(item.status);
    } else if (campo.field === 'situacaoDaFatura') {
      return this.getSituacaoFatura(item.situacaoDaFatura);
    } else if (campo.field === 'status') {
      return this.getStatusClass(item.status);
    }

    return '';
  }

  getSituacaoClass(status: string) {
    switch (status) {
      case 'Ativo':
        return 'ativo';
      case 'Inativo':
        return 'inativo';
      default:
        return 'blocked';
    }
  }

  newDate(date: string) {
    return new Date(date);
  }

  selectAll(event: any) {
    let checked = event.target?.checked;
    this.selectedItems = [];
    // Itera sobre todos os itens e define o status de seleção com base no checkbox do cabeçalho
    this.dados.forEach((item) => {
      item.selected = checked;
      if (checked) {
        this.selectedItems.push(item); // Adiciona à lista de itens selecionados
        this.enviarSelecionados.emit(this.selectedItems);
      } else {
        this.selectedItems = []; // Limpa a lista de itens selecionados se o checkbox estiver desmarcado
        this.enviarSelecionados.emit(this.selectedItems);
      }
    });
  }

  isSelected(item: any): boolean {
    return item.selected;
  }

  toggleSelection(item: any) {
    // Alterna o estado de seleção do item
    item.selected = !item.selected;
    if (item.selected) {
      if (!this.selectedItems.includes(item)) {
        this.selectedItems.push(item); // Adiciona à lista de itens selecionados se ainda não estiver presente
        this.enviarSelecionados.emit(this.selectedItems);
      }
    } else {
      const index = this.selectedItems.indexOf(item);
      if (index !== -1) {
        this.selectedItems.splice(index, 1); // Remove da lista de itens selecionados se estiver presente
        this.enviarSelecionados.emit(this.selectedItems);
      }
    }
  }

  capitalizeFirstLetter(status: string): string {
    return TransformText.capitalizeFirstLetter(status);
  }

  selectFavorite(item: any) {
    item.favorito = !item.favorito;
    this.selecionaFavorito.emit(item);
  }

  apertaBotao(event: any) {
    this.selecionaBotao.emit(event);
  }

  handleApelido(item: any) {
    item.editando = !item.editando;
  }
  salvarApelido(item: any) {
    item.editando = false
    this.mudarApelido.emit(item);
  }
}
