<div
  #formasPagamento
  id="formas-pagamento"
  class="formas-pagamento"
  *ngIf="!sucessExibirPdf"
>
  <mat-tab-group (selectedTabChange)="tabChanged($event)">
    <mat-tab
      [disabled]="payment.disabled"
      *ngFor="let payment of paymentMethods"
      class="pagamento-tab"
    >
      <ng-template mat-tab-label>
        <div
          [id]="gerarId(payment.name)"
          class="formas-pagamento_conteudo-flex"
          (click)="payment.disabled ? '' : selectPayment(payment.id)"
        >
          <div
            role="img"
            class="formas-pagamento_conteudo-flex_img"
            aria-label="Forma de Pagamento"
          >
            <agv-icon
              [name]="payment.icon"
              class="img-inativo"
              title="{{ payment.alt }}"
            ></agv-icon>
            <agv-icon
              [name]="payment.iconActive"
              class="img-ativo"
              title="{{ payment.altAtivo }}"
            ></agv-icon>
          </div>
          <div
            class="formas-pagamento_conteudo-flex_nome"
            [innerHTML]="payment.name"
          ></div>
        </div>
      </ng-template>
      <pagamento-fatura
        *ngIf="mostrarFaturaComponent(payment.id)"
        [paymentMethodType]="paymentMethod"
        [fatura]="fatura"
        (sucessoExibirPdf)="exibirPDF($event)"
        [parcela]="parcela"
        [logado]="logado"
        [banco]="bancoSelecionado"
      ></pagamento-fatura>
      <div class="pag-cartao" *ngIf="mostrarFormularioCartao">
        <div class="pag-cartao__pop-up" *ngIf="showPopCartao">
          <agv-icon [name]="'icone_x'" class="pag-cartao__pop-up__icone" (click)="atualizaStatus()"></agv-icon>
          <iframe *ngIf="url !== ''"
            [src]="url | safe : 'url'"
            class="iframe-width"
            load="lazy"
          ></iframe>
        </div>
        <app-notificacao-processo
        *ngIf="!showError"
        [tipoMensagem]="'atencao'"
        [tituloMensagem]="'Pagamento com cartão de crédito'"
        [mensagem]="'Você está sendo redirecionado para a página de pagamento do parceiro.<br/> Aguarde...'"
        [tabindex]="0"
        ></app-notificacao-processo>
        <app-notificacao-processo
        *ngIf="showError"
        [tipoMensagem]="'erro'"
        [tituloMensagem]="'Pagamento com cartão de crédito'"
        [mensagem]="msgError"
        [tabindex]="0"
        ></app-notificacao-processo>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<app-notificacao-processo
  id="pagto-fatura-msg-sucesso"
  *ngIf="sucessExibirPdf"
  [tipoMensagem]="'sucesso'"
  [tituloMensagem]="'SOLICITAÇÃO DE 2a VIA DE FATURA CONCLUÍDA COM SUCESSO!'"
  [fontWeightTitle]="500"
  [fontWeightDescription]="400"
  [mensagem]="
    'Se você realizou o pagamento, aguarde até 2 dias úteis para confirmação.'
  "
>
</app-notificacao-processo>
